<template>
  <div class="sidebar-wrap">
    <div class="user-info-wrap">
      <div class="user-avatar-wrap">
        <img :src="userInfo.avatar" :alt="userInfo.name">
      </div>
      <div class="user-name">{{userInfo.name}}</div>
      <div class="user-id">ID:{{userInfo.id}}</div>
      <div class="user-type">
        <i></i>
        <span>{{userIdentity}}</span>
      </div>
      <!-- <div class="join-vip">购买VIP</div> -->
    </div>
    <div class="sidebar-nav">
      <el-menu :unique-opened="true" @open="handleOpen" @close="handleClose" active-text-color="#d19d7e">
        <el-submenu index="1">
          <template slot="title">
            <span>账户管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="1-1" @click="toUserInfo">个人资料</el-menu-item>
            <el-menu-item index="1-2" @click="toAccountSecurity">账户安全</el-menu-item>
            <el-menu-item index="1-3" @click="toUserAddress">收货地址</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">
            <span>我的任务</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="2-1" @click="toSignIn">签到</el-menu-item>
            <!-- <el-menu-item index="2-2">分享</el-menu-item> -->
          </el-menu-item-group>
        </el-submenu>
        <!-- 老会员 运营商都没有剩余套餐资源 -->
        <el-menu-item index="3" v-if="userInfo.roleId != '4' && userInfo.roleId != 'de9de2f006e145a29d52dfadda295353'" @click="toVipPrivilege">
          <span slot="title">VIP特权</span>
        </el-menu-item>
        <!-- 老会员 运营商都没有资产管理 -->
        <!-- <el-menu-item index="4" v-if="userInfo.roleId != '4' && userInfo.roleId != 'de9de2f006e145a29d52dfadda295353'">
          <span slot="title" @click="toAssetManagement">资产管理</span>
        </el-menu-item> -->
        <el-submenu index="4" v-if="userInfo.roleId != '4' && userInfo.roleId != 'de9de2f006e145a29d52dfadda295353'">
          <template slot="title">
            <span>资产管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="4-1" @click="toAssetDetails">收支明细</el-menu-item>
            <el-menu-item index="4-2" @click="toMyShare">我的分润</el-menu-item>
            <el-menu-item index="4-3" @click="toECoin">我的e币</el-menu-item>
            <el-menu-item index="4-4" @click="toExchange">兑换提现</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="5" @click="orderManagement">
          <span slot="title">订单管理</span>
        </el-menu-item>
        <!-- 高级会员 = 合伙人 = 分销商才显示 -->
        <!-- <el-menu-item index="6" v-if="userInfo.roleId == '38e045588e0e4ce19b80ca40f5325934'">
          <span slot="title" @click="toSubAccountManagement">子账号管理</span>
        </el-menu-item> -->
        <el-submenu index="6" v-if="userInfo.roleId == '38e045588e0e4ce19b80ca40f5325934'">
          <template slot="title">
            <span>子账号管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="6-1" @click="toSubAccountList">子账号列表</el-menu-item>
            <el-menu-item index="6-2">我的邀请码</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        
        <!-- <el-menu-item index="7">
          <span slot="title">成为供应商</span>
        </el-menu-item> -->
        <el-submenu index="7">
          <template slot="title">
            <span>商品管理</span>
          </template>
          <el-menu-item-group> 
            <el-menu-item index="7-1" @click="toCommodityList">商品列表</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="8" @click="toShopCart">
          <span slot="title">购物车</span>
        </el-menu-item>
        <!-- <el-menu-item index="9">
          <span slot="title">团购活动</span>
        </el-menu-item> -->
        <el-menu-item index="9" @click="toWorksCenter">
          <span slot="title">作品中心</span>
        </el-menu-item>
        <el-menu-item index="10" @click="toCollectionRecord">
          <span slot="title">我的收藏</span>
        </el-menu-item>
        <el-menu-item index="11" @click="toMessageCenter">
          <span slot="title">消息中心</span>
        </el-menu-item> 
        <el-menu-item index="12" @click="toDownloadRecord">
          <span slot="title">下载记录</span>
        </el-menu-item>
        <el-menu-item index="13" @click="toBrowseRecords">
          <span slot="title">浏览记录</span>
        </el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
  import dump from "@/util/dump";
  export default {
    data(){
      return {
        userInfo: {}
      }
    },
    created() {
      this.init();
    },
    computed:{
      userIdentity(){
        let identity = "";
        switch(this.userInfo.roleId){
          case '3': identity = '注册用户';
          break;
          case '5': identity = '季会员';
          break;
          case '6': identity = '年会员';
          break;
          case '38e045588e0e4ce19b80ca40f5325934': identity = '高级会员';
          break;
          case 'de9de2f006e145a29d52dfadda295353': identity = '运营商';
          break;
          case '4': identity = '会员';
          break;
          case '7': identity = '子账号';
          break;
        }
        return identity;
      }
    },
    methods: {
      init(){
        this.userInfo.name = localStorage.getItem("name");
        this.userInfo.token = localStorage.getItem("token");
        this.userInfo.id = localStorage.getItem("phone");
        this.userInfo.roleId = localStorage.getItem("role_id");
        this.userInfo.avatar = localStorage.getItem("userAvatar") ? localStorage.getItem("userAvatar") : "./icon/user-default-avatar.jpg";
      },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
    
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      toUserInfo(){
        const params = {
          type: 1,
          link: "/usercenter/userInfo"
        }
        dump.link(params);
      },
      toUserAddress(){
        dump.link({
          type: 1,
          link: "/usercenter/receivingAddress"
        });
      },
      // 跳转到VIP特权页
      toVipPrivilege(){
        dump.link({
          type: 1,
          link: "/usercenter/packageResources"
        });
      },
      // 跳转到子账号管理
      // toSubAccountManagement(){
      //   dump.link({
      //     type: 1,
      //     link: "/usercenter/subAccountManagement"
      //   });
      // },
      // 跳转到子账号列表
      toSubAccountList(){
        dump.link({
          type: 1,
          link: "/usercenter/subAccountList"
        })
      },
      orderManagement(){
        dump.link({
          type: 1,
          link: "/usercenter/orderManagement"
        });
      },
      // 跳转到资产管理页
      // toAssetManagement(){
      //   dump.link({
      //     type: 1,
      //     link: "/usercenter/assetManagement"
      //   })
      // },
      // 跳转到收支明细
      toAssetDetails(){
        dump.link({
          type: 1,
          link: "/usercenter/assetDetails"
        })
      },
      // 跳转到我的分润
      toMyShare(){
        dump.link({
          type: 1,
          link: "/usercenter/myShare"
        })
      },
      // 跳转到我的e币 
      toECoin(){
        dump.link({
          type: 1,
          link: "/usercenter/eCoin"
        })
      },
      // 跳转到兑换提现 
      toExchange(){
        dump.link({
          type: 1,
          link: "/usercenter/exchange"
        })
      },
      // 跳转到商品管理
      // toCommodityManagement(){
      //   dump.link({
      //     type: 1,
      //     link: "/usercenter/commodityManagement"
      //   })
      // },
      // 跳转到商品列表 
      toCommodityList(){
        dump.link({
          type: 1,
          link: "/usercenter/commodityList"
        })
      },
      // 跳转到会员签到 
      toSignIn(){
        dump.link({
          type: 1,
          // link: "/usercenter/signIn"
          link: "/usercenter/signin"
        })
      },
      // 跳转到账号安全
      toAccountSecurity(){
        dump.link({
          type: 1,
          // link: "/usercenter/accountSecurity"
          link: "/usercenter/accountSecurity"
        })
      },
      // 跳转到购物车
      toShopCart(){
        dump.link({
          type: 3,
          link: "/usercenter/shopCart"
        })
      },
      // 跳转到我的收藏 
      toCollectionRecord(){
        dump.link({
          type: 1,
          link: "/usercenter/collectionRecord"
        })
      },
      // 跳转到作品中心
      toWorksCenter(){
        dump.link({
          type: 1,
          link: "/usercenter/worksCenter"
        })
      },
      // 跳转到下载记录页
      toDownloadRecord(){
        dump.link({
          type: 1,
          link: "/usercenter/downloadRecord"
        })
      },
      // 跳转到浏览记录页
      toBrowseRecords(){
        dump.link({
          type: 1,
          link: "/usercenter/browseRecords"
        })
      },
      // 跳转到消息中心
      toMessageCenter(){
        dump.link({
          type: 1,
          link: "/usercenter/messageCenter"
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  .sidebar-wrap{
    width: 14.0625%;
    background-color: #fff;
    .user-info-wrap{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 29px 0;
      .user-avatar-wrap{
        width: 37.0370%;
        border-radius: 100%;
        overflow: hidden;
      }
      .user-name{
        margin-top: 7px;
        font-size: 17px;
        color: $select-search-category-title;
      }
      .user-id{
        font-size: 17px;
        color: $font-default-foot-link-color;
      }
      .user-type{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 11px;
        i{
          display: block;
          width: 24px;
          height: 24px;
          background-image: url("~@/../public/icon/userCenter/userInfo/register-user-icon@2x.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          margin-right: 7px;
        }
        span{
          font-size: 17px;
          color: $select-search-category-title;
        }
      }
      .join-vip{
        width: 74.0741%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background-color: #d19d7e;
        border-radius: 3px;
        margin-top: 20px;
        font-size: 15px;
        color: #fff;
        cursor: pointer;
      }
    }
    .sidebar-nav{
      /deep/ .el-menu{
        border-right: 0;
        .el-submenu{
          .el-submenu__title{
            position: relative;
            height: 45px;
            line-height: 45px;
            span{
              font-size: 17px;
              color: $font-default-color;
            }
            &:hover{
              background-color: #F5F5F5;
            }
            
          }
          &.is-opened{
            .el-submenu__title{
              background-color: #F5F5F5;
              &:after{
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 3px;
                height: 100%;
                background-color: #d19d7e;
              }
            }
          }
          .el-menu-item-group__title{
            display: none;
          }
          .el-menu-item{
            height: 45px;
            line-height: 45px;
            padding: 0 !important;
            text-indent: 2em;
            font-size: 15px;
            color: $font-default-foot-link-color;
            &:focus,
            &:hover{
              outline: 0;
              background-color: #fff;
            }
            &:after{
              display: none;
            }
          }
        }
        .el-menu-item{
          height: 45px;
          line-height: 45px;
          span{
            font-size: 17px;
            color: $font-default-color;
          }
          &:hover{
            background-color: #F5F5F5;
          }
          &:focus{
            background-color: #fff;
          }
          &.is-active{
            background-color: #F5F5F5;
            span{
              color: #d19d7e;
            }
            &:after{
              position: absolute;
              left: 0;
              top: 0;
              content: "";
              width: 3px;
              height: 100%;
              background-color: #d19d7e;
            }
          }
        }
        
      }
    }
  }
</style>
