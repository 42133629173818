<template>
  <div class="header-wrap">
    <div class="header-nav" :style="`padding-top: ${isFixed ? headNavH : 0}px;`">
      <!-- <div class="header-nav" style="padding-top: is-fixed"> -->
      <nav :class="{ 'is-fixed': isFixed }" ref="headerNav">
        <div class="sales-tips" v-show="closeSales">
          <!-- <div class="pic-wrap" @click="toSalesTipsDetail">
            <img src="@/../public/img/sales-tips.png" alt="">
          </div>
          <i class="el-icon-circle-close" @click="closeSalesTips"></i> -->
        </div>
        <div class="header-inner-wrap navbar navbar-expand-lg bg-dark navbar-light bg-light">
          <div v-if="this.headerLogo == '' || this.headerLogo == null">
            <a class="navbar-brand no-margin-r" href="/">
              <!-- <img class="nav-logo"
              src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d73e503-6704-4a2f-815b-a48cf5d8dcbf.png" /> -->
              <img class="nav-logo" src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/zdmj_black.png" />
              <img class="nav-split"
                src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/e16bf8c5-c4f8-4dc6-a726-66a254ed3faa.png" />
              <span class="nav-title">{{ $t("homeSumaryInfo") }}</span>
            </a>
          </div>
          <div v-else>
            <a class="navbar-brand no-margin-r" href="/">
              <!-- <img class="nav-logo"
              src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0d73e503-6704-4a2f-815b-a48cf5d8dcbf.png" /> -->
              <img class="nav-logo" :src="this.headerLogo" />
              <!-- <img class="nav-split"
                src="https://e-home.oss-cn-shenzhen.aliyuncs.com/file/e16bf8c5-c4f8-4dc6-a726-66a254ed3faa.png" /> -->
              <!-- <span class="nav-title">{{ $t("homeSumaryInfo") }}</span> -->
            </a>
          </div>
          <button @click=" showHideNavbar " class="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse center-link" :class="{ 'show': showHideNavbarType }"
            id="navbarNavDropdown">
            <ul class="navbar-nav">
              <li class="nav-item" @click="toLink(item, index)" :class="{ 'active': navIndex == item.id }" :key=" item.id "
                v-for="(   item, index   ) in    newNavLinkList   ">
                <a class="nav-link">{{ item.title }}</a>
                <!-- <div class="sub-nav-wrap" v-if="index == 1">
                  <ul>
                    <li v-for="(obj, idx) in carefullyChosenGoods" :key="idx" @click.stop="toSelectGoods(obj)">
                      {{ obj.name }}</li>
                  </ul>
                </div> -->
                <!-- <div class="sub-nav-wrap" v-if="index == 2">
                  <ul>
                    <li v-for="(obj, idx) in subNavitems" :key="idx" @click.stop="toSubLink(obj)">{{ obj.name }}</li>
                  </ul>
                </div> -->
                <div class="sub-nav-wrap" v-if=" index == 1">
                  <ul>
                    <li v-for="(   obj, idx   ) in    subNavitems   " :key=" idx " @click.stop="toSubLink(obj)">{{ obj.name }}
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <!-- <div class="search-box">
              <div class="search-input-box">
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle search-select" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-expanded="false">
                    {{ selectInfo.title }}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#" v-for="(item, index) in selectInfoList" :key="index"
                      @click="selectInfo = item">{{ item.title }}</a>
                  </div>
                </div>
                <div class="search-split-box">
                  <img src="../../public/icon/search-split@2x.png" class="search-split" />
                </div>
                <input v-model="keyWords" class="search-input-info" :placeholder="searchPlaceholder"
                  @keyup.enter="search" />

                <div class="search-split-box margin-left-auto">
                  <img src="../../public/icon/product-distingush@2x.png" class="search-icon" />
                </div>
                <div class="search-split-box">
                  <img src="../../public/icon/search-split@2x.png" class="search-split" />
                </div>
                <div class="search-split-box margin-right-22" @click="search()">
                  <img src="../../public/icon/search-icon@2x.png" class="search-icon" />
                </div>
              </div>

            </div> -->
          </div>

          <div class="header-nav-r">
            <div class="login-wrap" v-if=" userInfo.token != null && userInfo.token != 'null' ">
              <a href="javascript:void(0)" class="navbar-brand" @click=" toShopCart ">
                <i class="el-icon-shopping-cart-2"></i>
              </a>
              <!-- <a class="navbar-brand" @click="toLinkDump(item)" v-for="(item,index) in iconList" :key="index">
                <img class="icon" :src="item.icon" />
              </a> -->
              <a class="navbar-brand user-avatar" @click.stop="toPersonCenter()" @mouseenter=" showUserInfoItems ">
                <img class="avatar" :src=" userInfo.avatar " />
                <div :class=" showUserInfo? 'active': '' " class="user-info-items-wrap" @click.stop
                  @mouseleave=" hiddenUserInfoItems ">
                  <div class="user-info">
                    <img class="avatar" :src=" userInfo.avatar " alt="">
                    <div class="user-info-detail">
                      <span class="name">{{ userInfo.name }}</span>
                      <span class="phone">ID:{{ userInfo.id }}</span>
                    </div>
                  </div>
                  <div class="user-info-item">
                    <div class="item" @click=" toPersonCenter ">
                      <i class="el-icon-user"></i>
                      <span>个人中心</span>
                    </div>
                    <div class="item" @click=" toAccountSettings ">
                      <i class="el-icon-setting"></i>
                      <span>账户设置</span>
                    </div>
                    <div class="item" @click=" toOrderManagement ">
                      <i class="el-icon-tickets"></i>
                      <span>订单管理</span>
                    </div>
                    <div class="item" @click=" toCollectionRecord ">
                      <i class="el-icon-star-off"></i>
                      <span>我的收藏</span>
                    </div>
                    <div v-if="this.shopId" class="item" @click=" toMemberPlatform ">
                      <i class="el-icon-user"></i>
                      <span>会员后台</span>
                    </div>
                    <div v-if="this.shopId" class="item" @click=" copyLink ">
                      <i class="el-icon-user"></i>
                      <span>复制分享链接</span>
                    </div>
                  </div>
                  <div class="user-logout" @click=" logout ">
                    退出
                  </div>
                </div>
              </a>
            </div>
            <div class="logout-wrap" v-else>
              <!-- <a href="#" class="navbar-brand">
                <i class="el-icon-shopping-cart-2"></i>
              </a> -->
              <a href="javascript:void(0);" class="navbar-brand">
                <span @click=" login ">登录</span>
                <span @click=" register ">注册</span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import dump from "../util/dump.js"
import i18n from '../i18n'
export default {
  props: ['navSelIndex'],
  data() {
    return {
      userInfo: {},
      iconList: [{
        "icon": "./icon/recharge@2x.png",
        type: 1,
        link: '#'
      },
      {
        "icon": "./icon/sigin@2x.png",
        type: 1,
        link: '#'
      },
      {
        "icon": "./icon/invitation@2x.png",
        type: 1,
        link: '#'
      }, {
        "icon": "./icon/message@2x.png",
        type: 1,
        link: '#'
      }
      ],
      navLinkList: [{
        title: i18n.t('homeTitle'),
        link: "/",
        type: 1,
        id: 1
      },
      // {
      //   title: i18n.t('carefullyChosenGoodsTitle'),
      //   link: "/carefullyChosenGoods",
      //   type: 1,
      //   id: 8
      // },
      {
        title: i18n.t('shopTitle'),
        link: "/mallIndex",
        type: 1,
        id: 2
      },
      {
        title: i18n.t('navToolTitle'),
        link: "/planeScheme",
        type: 1,
        id: 3
      },
      {
        title: i18n.t('scenePurchaseTitle'),
        link: "/scenePurchase",
        type: 1,
        id: 4
      },
      // {
      //   title: i18n.t('renovationTitle'),
      //   link: "/room",
      //   type: 1,
      //   id:5
      // },
      // {
      //   title: i18n.t('navThreeDimensionalTitle'),
      //   link: "/threeDimensional",
      //   type: 1,
      //   id: 4
      // },
      {
        title: i18n.t('tModeTitle'),
        link: "/tMode",
        type: 1,
        id: 6
      },
      // {
      //   title: i18n.t('vipPrivilegeTitle'),
      //   link: "/vipprivilege",
      //   type: 1,
      //   id: 6
      // },
      // {
      //   title: i18n.t('scenePurchaseTitle'),
      //   link: "/scenePurchase",
      //   type: 1,
      //   id: 6
      // },
      {
        title: i18n.t('videoTutorialTitle'),
        link: "/videoTutorial",
        type: 1,
        id: 7
      }

      ],
      navIndex: this.navSelIndex >= 0 ? this.navSelIndex : -1,
      showHideNavbarType: false,
      showUserInfo: false, selectInfoList: [
        {
          title: '搜单品',
          id: 1,
        },
        {
          title: '搜套餐',
          id: 2,
        }
      ],
      selectInfo: {
        title: '搜单品',
        id: 1,
      },
      // carefullyChosenGoods: [
      //   {
      //     name: "拎包套餐",
      //     link: "/bagCheck",
      //     type: 3
      //   },
      //   {
      //     name: "拎包单品",
      //     link: "/bagCheckSingle",
      //     type: 3
      //   },
      //   // {
      //   //   name: "明星同款",
      //   //   link: "/starSameStyle",
      //   //   type: 3
      //   // },
      //   // {
      //   //   name: "二维方案",
      //   //   link: "#",
      //   //   type: 3
      //   // }
      // ],
      subNavitems: [
        {
          name: "全部商品",
          link: "/search",
          type: 3,
          params: {
            categoryId: 2
          }
        },
        // {
        //   name: "臻选家具",
        //   link: "/choosingFurniture",
        //   type: 3
        // },
        {
          name: "严选软装",
          link: "/softOutfit",
          type: 3
        },
        {
          name: "优选品牌",
          link: "/optimizingBrand",
          type: 3
        },
        // {
        //   name: "精选套餐",
        //   link: "/packageList",
        //   type: 3
        // },
      ],
      keyWordsList: ["现代客厅", "卧室", "极简"],
      keyWords: "",
      searchPlaceholder: '搜商品套餐名称、编码',
      isFixed: false,
      offsetTop: 0,
      closeSales: true,
      headNavH: 0,
      pageStr: "",
      headerLogo: "",
      shopId:""
    }
  },
  created() {
    this.userInfo.name = localStorage.getItem("name");
    this.userInfo.token = localStorage.getItem("token");
    this.userInfo.id = localStorage.getItem("phone");
    this.userInfo.roleId = localStorage.getItem("role_id");
    this.userInfo.avatar = localStorage.getItem("userAvatar") ? localStorage.getItem("userAvatar") : "./icon/user-default-avatar.jpg";
    this.shopId = this.cookie.getCookie("shopId")
    // this.$nextTick(()=>{
      this.headerLogo = this.cookie.getCookie("headerLogo")
    // })
    
  },
  mounted() {
    window.addEventListener('scroll', this.initHeight);
    this.$nextTick(() => {
      this.offsetTop = this.$refs.headerNav.offsetTop;
      window.onload = () => {
        this.headNavH = this.$refs.headerNav.offsetHeight;
      }
    })
    console.log("offsetTop", this.offsetTop, "headNavH", this.headNavH)
    console.log("aaa", location.hash.substring(location.hash.indexOf("#") + 2, location.hash.indexOf("?")))
  },
  computed: {
    newNavLinkList() {
      const navLink = this.navLinkList;
      // 子账号不显示VIP专属特权页面
      if (this.userInfo.roleId == "7") {
        navLink.forEach((item, index) => {
          if (item.link == "/vipprivilege") {
            navLink.splice(index, 1);
          }
        })
      }
      return navLink;
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.initHeight, false);
  },
  methods: {
    initHeight() {
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // this.isFixed = scrollTop > this.offsetTop ? false : true;
      // console.log("scrollTop",scrollTop,"isFixed",this.isFixed,"offsetTop",this.offsetTop)
    },
    showHideNavbar: function () {
      this.showHideNavbarType = !this.showHideNavbarType;
    },
    toLink: function (item) {
      if (item.id == this.navIndex) {
        return false;
      }
      this.showHideNavbarType = false;
      this.navIndex = item.id;
      this.toLinkDump(item);
    },
    toLinkDump: function (item) {

      dump.link(item);
      // if (item.type == 1) {
      // 	this.$router.push({
      // 		path: item.link
      // 	});
      // } else {
      // 	window.location.href = item.link;
      // }
    },
    // 跳转到登录页
    login() {
      const params = {
        type: 1,
        link: "/login"
      }
      dump.link(params);
    },
    // 跳转到注册页
    register() {
      const params = {
        type: 1,
        link: "/registered"
      }
      dump.link(params);
    },
    // 退出
    logout() {
      localStorage.clear();
      this.login();
    },
    search() {
      const that = this;
      if (that.selectInfo.id == 1) {
        //搜索单品
        let data = {
          type: 3,
          link: "/search",
          params: {
            keyWords: that.keyWords
          }
        };
        dump.link(data);
      } else if (that.selectInfo.id == 2) {
        //搜索套餐
        let data = {
          type: 3,
          link: "/packageList",
          params: {
            keyWords: that.keyWords
          }
        };
        dump.link(data);
      }
    },
    // 开启用户信息悬浮框
    showUserInfoItems() {
      this.showUserInfo = true;
    },
    // 关闭用户信息悬浮框
    hiddenUserInfoItems() {
      this.showUserInfo = false;
    },
    // 跳转到订单管理
    toOrderManagement() {
      const params = {
        type: 3,
        link: "/usercenter/orderManagement"
      }
      dump.link(params);
    },
    // 跳转到我的收藏
    toCollectionRecord() {
      const params = {
        type: 3,
        link: "/usercenter/collectionRecord"
      }
      dump.link(params);
    },
    // 跳转到账号设置 
    toAccountSettings() {
      dump.link({
        type: 3,
        link: "/usercenter/userinfo"
      })
    },
    // 跳转到个人中心 
    toPersonCenter() {
      const params = {
        type: 3,
        link: "/usercenter"
      }
      dump.link(params);
    },
    toMemberPlatform(){
      this.$api.epeida({
          token: localStorage.getItem("token")
        }).then(res => {
          dump.toMemberPlatform(res);
        })
    },
    async copyLink(){
      const netSuffix = this.cookie.getCookie("netSuffix")
      // const url = "http://localhost:8080/to-" + netSuffix
      const url = "https://myd0755.com/to-" + netSuffix

      try{
        await navigator.clipboard.writeText(url)
        alert("复制成功")
      }catch(e){
        alert("复制失败")
      }
    },
    // 跳转到购物车
    toShopCart() {
      const params = {
        type: 3,
        link: "/usercenter/shopCart"
      }
      dump.link(params);
    },
    toSubLink(obj) {
      dump.link({
        type: obj.type,
        link: obj.link,
        params: obj.params
      })
    },
    closeSalesTips() {
      this.closeSales = !this.closeSales;
      this.$nextTick(() => {
        this.headNavH = this.$refs.headerNav.offsetHeight;
      })
    },
    toSalesTipsDetail() {
      dump.link({
        link: "/carefullyChosenGoods",
        type: 1
      })
    },
    toSelectGoods(obj) {
      dump.link({
        type: 1,
        link: obj.link
      })
    }
  }
}
</script>

<style scoped="scoped">
.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.no-margin-r {
  margin-right: 0;
}

.sales-tips {
  position: relative;
}

.sales-tips .pic-wrap {
  cursor: pointer;
}

.sales-tips .el-icon-circle-close {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 20px;
  color: #a39f9e;
  z-index: 900;
  cursor: pointer;
}

.navbar {
  display: flex;
  justify-content: space-evenly;
}

.navbar-collapse {
  flex: 0.7;
  flex-shrink: 0;
  justify-content: center;
}

.navbar-collapse .navbar-nav {
  flex: 0.75;
  justify-content: space-evenly;
}

.navbar-toggler {
  order: 1;
}

.navbar-brand {
  flex: 0.25;
}

.navbar-brand .el-icon-shopping-cart-2 {
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #d19d7e;
}

.header-nav-r {
  flex: 0.2;
}

.logout-wrap .navbar-brand span {
  position: relative;
  padding: 0 5px;
  font-size: 14px;
  color: #fff;
}

.logout-wrap .navbar-brand span:first-child:after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 1px;
  height: 100%;
  background-color: #fff;
  z-index: 10;
}

.user-avatar {
  position: relative;
}

.user-info-items-wrap {
  position: absolute;
  top: 3.3125rem;
  left: -9.125rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  width: 13.33rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  transform: scale(0);
  transform-origin: 80% top;
  transition: all 0.3s;
  opacity: 0;
}

.user-avatar .active {
  transform: scale(1);
  opacity: 1;
  color: initial !important;
}

/* .user-avatar:hover .user-info-items-wrap{
    
  } */
.user-info-items-wrap .user-info {
  display: flex;
  background: #32353c;
  padding: 1rem;
}

.user-info-items-wrap .user-info-detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: .89rem;
}

.user-info-items-wrap .user-info-detail .name {
  font-size: .81rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #fff;
}

.user-info-items-wrap .user-info-detail .phone {
  font-size: .81rem;
  font-family: ArialMT;
  font-weight: 400;
  color: #fff;
}

.user-info-items-wrap .user-info-item {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 0 .28rem;
}

.user-info-items-wrap .user-info-item .item {
  padding: .36rem .64rem;
  cursor: pointer;
}

.user-info-items-wrap .user-info-item .item i {
  margin-right: 5px;
}

.user-info-items-wrap .user-info-item .item span {
  font-size: 14px;
}

.user-info-items-wrap .user-info-item .item i,
.user-info-items-wrap .user-info-item .item span {
  display: inline-block;
  vertical-align: middle;
}

.user-info-items-wrap .user-info-item .item:hover i,
.user-info-items-wrap .user-info-item .item:hover span {
  color: #d19d7e;
}

.user-info-items-wrap .user-logout {
  background-color: #f3f3f3;
  font-size: .81rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #727171;
  line-height: 2rem;
  cursor: pointer;
}

.user-info-items-wrap .user-logout:hover {
  color: #d19d7e;
}

.bg-dark {
  background-color: #000000 !important;
}

.nav-split {
  height: 2.1875rem;
  margin: auto 0.75rem;
}

.nav-logo {
  width: 170px;

  /* height: 35px; */
}

.nav-title {
  height: 0.75rem;
  font-size: 0.8125rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #FFFFFF;
}

.nav-link {
  display: unset !important;
  padding: unset !important;
  text-decoration: none !important;
  transition: unset !important;
  font-size: 0.9375rem !important;
  font-family: Source Han Sans SC !important;
  font-weight: 400 !important;
  color: #FFFFFF !important;
  cursor: pointer;
}




.active {
  color: #d19d7e !important;
}

.active .nav-link {
  color: #d19d7e !important;
  border-bottom: 2px solid #d19d7e;
}

.nav-link {
  line-height: 1.875rem;
}

.navbar-light .navbar-toggler {
  background-color: #FFFFFF
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.avatar {
  width: 2.3125rem;
  height: 2.3125rem;
  border-radius: 50%;
  cursor: pointer;
}

.nav-item {
  position: relative;
}

.nav-item:hover .sub-nav-wrap {
  transform: scale(1);
  opacity: 1;
}

.sub-nav-wrap {
  position: absolute;
  top: 38px;
  left: -50%;
  /* margin-left: -20px; */
  width: 100px;
  transform: scale(0);
  transform-origin: center top;
  transition: all 0.3s;
  opacity: 0;
  z-index: 100;
}

.sub-nav-wrap:after {
  position: absolute;
  top: -8px;
  left: 50%;
  content: "";
  width: 0;
  height: 0;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-top-width: 0;
}

.sub-nav-wrap ul {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.sub-nav-wrap li {
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  color: #231915;
  cursor: pointer;
}

.sub-nav-wrap li:hover {
  background-color: #f0f0f0;
  color: #d19d7e;
}


.search-box {
  position: absolute;
  width: 100%;
  bottom: 5.625rem;
}

.search-box>.search-input-box {
  height: 4rem;
  background: #FFFFFF;
  border-radius: 0.5rem;
  margin: auto;
  z-index: 9;
  display: flex;
}

.search-box>.search-input-box {
  width: 52.5rem;
}

.key-words-box {
  width: 52.5rem;
}

.search-input-info {
  width: 34.375rem;
}

.container-index {
  padding: 0 6.1875rem;
}

.search-icon {
  /* height: 1.875rem; */
  width: 1.875rem;
  margin: auto 0;
  cursor: pointer;
}

.search-select {
  background: #ffffff !important;
  border: unset !important;
  border: unset !important;
  color: #000 !important;
  box-shadow: unset !important;
  height: 100% !important;
  border-radius: 0.5rem;
}

.key-words-box {
  margin: auto;
  padding: 1.5rem;
  font-size: 0.9375rem;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #FFFFFF;
  text-align: left;
}

.key-words {
  padding: 0 0.9375rem;
  cursor: pointer;
}

.search-input-info {
  border: none;
  outline: medium;
}

.search-box {
  position: absolute;
  top: 50px;
  width: 100%;
}

.margin-left-auto {
  margin-left: auto !important;
}

.search-split-box {
  height: 100%;
  display: flex;
  margin: 0 0.5rem;
}

@media screen and (max-width: 1430px) {

  /* .navbar-brand{
      flex: 0.25;
    } */
  .navbar-collapse {
    flex: 0.5;
    flex-shrink: 0;
    justify-content: center;
  }

  .navbar-collapse .navbar-nav {
    flex: 1;
    justify-content: space-evenly;
  }

  .header-nav-r {
    display: flex;
    justify-content: flex-end;
    flex: 0.25;
  }
}

@media screen and (max-width: 1199px) {
  .navbar-brand {
    flex: 0.3;
  }

  .navbar-collapse {
    flex: 0.46;
    flex-shrink: 0;
    justify-content: center;
  }

  .navbar-collapse .navbar-nav {
    flex: 1;
    justify-content: space-evenly;
  }

  .header-nav-r {
    display: flex;
    justify-content: flex-end;
    flex: 0.24;
  }

  .user-avatar {
    margin-right: 0;
  }
}

@media screen and (max-width: 991px) {
  .navbar-brand {
    flex: unset;
  }

  .navbar-collapse {
    flex: 1;
    flex-shrink: 0;
    justify-content: center;
  }

  .header-nav-r {
    display: flex;
    justify-content: flex-end;
    flex: unset;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }

  .header-nav-r {
    order: 0;
  }

  .navbar-collapse {
    order: 2;
  }
}

@media screen and (max-width: 610px) {
  .header-nav-r {
    display: none;
  }
}

@media screen and (max-width: 399px) {

  .nav-split,
  .nav-title {
    display: none;
  }

  .navbar {
    justify-content: space-between;
  }
}




/* @media (min-width: 375px) {
    .bg-dark {
      max-width: 100% !important;
    }

    .nav-link {
      margin: auto 0.1rem;
    }

    .center-link {
      margin-left: 0;
    }
  }

  @media (min-width: 768px) {
    .nav-link {
      margin: auto 0.2rem;
    }
  }

  @media (min-width: 992px) {
    .nav-link {
      margin: auto 0.4rem;
      padding-bottom: 1.2rem !important;
    }

    .center-link {
      margin-left: 12.0625rem;
    }
  }

  @media (min-width: 1600px) {
    .bg-dark {
      max-width: 100% !important;
      padding-left: 4.5rem !important;
      padding-right: 4.5rem !important;
    }

    .active {}

    .nav-link {
      margin: auto 2.1875rem;
    }
  } */
</style>
