<template>
  <div class="wrap">
    <head-nav></head-nav>
    <div class="con-wrap">
      <side-bar></side-bar>
      <div class="pages-con-wrap">
        <div class="sub-account-management-bg">
          <b-container fluid>
            <h2 class="page-title">子账号管理</h2>
            <div class="sub-account-management-con" v-if="subAccountArr.length > 0">
              <table>
                <thead>
                  <tr>
                    <th>
                      <el-checkbox v-model="checkedAll" @change="allChecked"></el-checkbox>
                    </th>
                    <th>序号</th>
                    <th>头像</th>
                    <th>用户名</th>
                    <th>性别</th>
                    <th>手机号</th>
                    <th>国家</th>
                    <th>省份</th>
                    <th>城市名称</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in subAccountArr" :key="index" @click.stop="select($event, index)">
                    <td>
                      <el-checkbox :value="item.checked"></el-checkbox>
                    </td>
                    <td>{{index}}</td>
                    <td>
                      <div class="avatar">
                        <img :src="item.avatar" :alt="item.nickName">
                      </div>
                    </td>
                    <td>{{item.nickName}}</td>
                    <td>{{item.gender == 1 ? '男' : '女' }}</td>
                    <td>{{item.phone}}</td>
                    <td>{{item.county}}</td>
                    <td>{{item.province}}</td>
                    <td>{{item.city}}</td>
                    <td>
                      <div class="operation">
                        <span class="edit" @click.stop="openEdit(item)">
                          <i class="el-icon-edit-outline" title="编辑"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-container>
        </div>
      </div>
    </div>
    <el-dialog
      title="账号权益修改"
      :visible.sync="editVisible"
      width="50%"
      center>
      <div class="account-equity-modification">
        <el-form :model="form" size="mini" :rules="rules" :key="editSubAccountId">
          <b-container fluid v-if="usableInfo.length > 0">
            <b-row>
              <b-col sm="12" md="12" lg="6" xl="6" v-for="(item, index) in usableInfo" :key="index">
                <div class="item">
                  <span>
                    <el-checkbox v-model="item.checked">{{item.resourceName}}{{item.calculateFlag == '1' ? "：" : ""}}</el-checkbox>
                  </span>
                  <!-- calculateFlag 1 可以计算 0 不可计算 -->
                  <!-- useFlag 1 资源可用 0 资源不可用 -->
                  <span v-if="item.calculateFlag == '1'">
                    <el-input 
                      v-if="item.resourceName == '商城商品折扣'" 
                      size="mini" 
                      v-model="item.resourceRemain" 
                      placeholder="设置范围0.35~1"
                      prop="resourceRemain"
                      type="discount"
                      ></el-input>
                    <el-input v-else size="mini" v-model="item.resourceRemain"></el-input>
                  </span>
                </div>
              </b-col>
              <b-col sm="12" md="12" lg="6" xl="6">
                <div class="item">
                  <span>截止时间：</span>
                  <el-date-picker
                    size="mini"
                    v-model="dateValue"
                    type="datetime"
                    placeholder="选择日期时间"
                    default-time="12:00:00">
                  </el-date-picker>
                </div>
              </b-col>
              <!-- <b-col sm="12" md="12" lg="6" xl="6">
                <div class="item">
                  <span>子账号开启/关闭：</span>
                  <el-switch
                    v-model="switchValue"
                    active-color="#13ce66"
                    inactive-color="#ff4949">
                  </el-switch>
                </div>
              </b-col> -->
            </b-row>
          </b-container>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import headNav from "@/components/Navbar";
  import sideBar from "@/components/userCenterSidebar";
  export default {
    components: {
      headNav,
      sideBar
    },
    data (){
      return {
        token: null,
        subAccountArr: [],
        checkedAll: false,
        editVisible: false,
        form: {
          
        },
        usableInfo: [],
        // switchValue: true,
        dateValue: "",
        editSubAccountId: "",
        rules: {
          resourceRemain: [
            { validator: this.validateResourceRemain, trigger: 'blur' }
          ]
        }
      }
    },
    created() {
      this.init();
    },
    computed: {
      dateToStr(){
        var dateTime = new Date(this.dateValue.toString());
        // var dateTime = this.dateValue;
        var year = dateTime.getFullYear();
        //js从0开始取
        var month = dateTime.getMonth()+1;
        var date = dateTime.getDate();
        var hour = dateTime.getHours();
        var minutes = dateTime.getMinutes();
        var second = dateTime.getSeconds();
 
        if(month<10){
            month = "0" + month;
        }
        if(date<10){
            date = "0" + date;
        }
        if(hour <10){
            hour = "0" + hour;
        }
        if(minutes <10){
            minutes = "0" + minutes;
        }
        if(second <10){
            second = "0" + second ;
        }
        return year+"-"+month+"-"+date+" "+hour+":"+minutes+":"+second;
      }
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getSubAccount();
      },
      // 获取子账号
      getSubAccount(){
        this.$api.subAccount({
          token: this.token
        }).then(res => {
          console.log(res);
          if(res.status == 100){
            this.subAccountArr = res.data;
            this.subAccountArr.forEach(item => {
              this.$set(item, "checked", false);
            })
          }
        }).catch(err => console.error(err))
      },
      // 全选
      allChecked(val){
        this.subAccountArr.forEach(item => {
          item.checked = val;
        })
      },
      // 选中子账号
      select(e, i){
        // 因为原生click事件会执行两次，第一次在label标签上，第二次在input标签上，故此处理
        if (e.target.tagName === "INPUT") return;
        const checkedArr = [];
        this.subAccountArr[i].checked = !this.subAccountArr[i].checked;
        console.log(this.subAccountArr)
        this.subAccountArr.forEach(item => {
          if (item.checked === true) {
            checkedArr.push(item)
          }
        })
        
        if (checkedArr.length === this.subAccountArr.length) {
          this.checkedAll = true;
        } else {
          this.checkedAll = false;
        }
      },
      // 打开子账号权益修改窗
      openEdit(obj){
        this.editVisible = !this.editVisible;
        this.editSubAccountId = obj.id;
        if(this.editVisible){
          this.getUsableInfo(obj)
          this.editItem = obj;
        }
      },
      // 获取子账号可用剩余功能信息
      getUsableInfo(obj){
        this.$api.getUsableInfo({
          userId: obj.userId
        }).then(res => {
          if(res.status == 100){
            res.data.sort((a, b) => a.calculateFlag - b.calculateFlag);
            this.usableInfo = res.data;
            this.usableInfo.forEach(item => {
              this.$set(item, 'checked', item.useFlag == "1" ? true : false);
            })
          }
        }).catch(err => console.error(err))
      },
      // 修改子账号权限
      submit(){
        console.log(this.dateToStr)
        if(!this.dateValue) return this.$message.error('截止时间不能为空！');
        const resourceIdArr = [],
              resourceQuantityArr = [],
              resourcestatusArr = [];
        this.usableInfo.forEach(item => {
          resourceIdArr.push(item.resourceId);
          resourceQuantityArr.push(item.resourceRemain);
          resourcestatusArr.push(item.checked ? 1 : 0);
        })
        
        this.$api.modifyPermissions({
          // subUserId 子账号的userId
          subUserId: this.usableInfo[0].userId,
          // resourceIdList 每项修改值的ID
          resourceIdList: resourceIdArr.join(","),
          // resourceQuantity 每项修改的值
          resourceQuantity: resourceQuantityArr.join(","),
          // resourcestatus  子账号每项功能开启状态 0 :关闭 1:开启
          resourcestatus: resourcestatusArr.join(","),
          // 会员功能的 到期时间
          expireDateList: this.dateToStr,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.editVisible = !this.editVisible;
            this.$message({
              message: '修改成功',
              type: 'success'
            });
          }
          
        }).catch(err => console.error(err))
      },
      // 验证用户输入折扣
      validateResourceRemain(){
        console.log(arguments)
        // if (value === '') {
        //   callback(new Error('请输入密码'));
        // } else {
        //   if (this.ruleForm.checkPass !== '') {
        //     this.$refs.ruleForm.validateField('checkPass');
        //   }
        //   callback();
        // }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/subAccountManagement/subAccountManagement";
</style>
